import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { apiRequest } from "../../../Middleware/API/apiRequest";
import { useAuth } from "../../Core/Context/AuthContext";
import { SOCIAL_MEDIA_LOGIN } from "../../../constants/apiRoutes";
import { login } from "../Utils/CommonUtil";
import { errorGenerator, ErrorFallback } from "smart-react";
const dashboardPath = "/products";
/**
 * Handle Redirection and User Data on Redirection
 */
const AzureRedirectComponent = () => {
  const navigate = useNavigate();
  const { updateUser } = useAuth();

  /**
   * Used to get the Tokens
   */
  useEffect(() => {
    // get auth code from url
    const urlParams = new URLSearchParams(window.location.search);
    const redirectAuthCode = urlParams.get("code");
    // get access token with formatted body
    getTokens(generateFormBody(redirectAuthCode));
  }, []);

  // generate form body for request to token endpoint
  function generateFormBody(authCode) {
    const details = {
      code: authCode,
      authGatewayType: "AZURECODE",
    };

    return details;
  }
  // redirect to login in case of error and show error message
  const onLoginError = ({ response }) => {
    errorGenerator({
      alert: true,
      message: JSON.parse(response?.error_description)?.StatusDesc,
    });
    navigate("/login");
  };

  // send request for id, access and refresh tokens
  async function getTokens(formBodyDetails) {
    // Default options are marked with *

    const data = await getTokenFromAPI(formBodyDetails);

    if (!data?.IsSuccess) {
      errorGenerator({
        title: "Data not Valid",
        message: data?.StatusDesc,
      });
      navigate("/");
    } else if (data) {
      const parts = data?.Payload?.UserName?.split(",");
      const username = parts[0];
      const password = parts[1];
      const dataItem = {
        grant_type: "password",
        AppId: process.env.APP_ID,
        device_id: navigator.userAgent,
        browser: navigator.userAgent,
        username: username,
        password: password,
        os: navigator.platform,
        SrcTypeId: "AZURECODE",
      };

      try {
        await login({ dataItem, updateUser, page: "social", onLoginError });
      } catch (e) {
        console.log(e);
      }
    }
  }

  /**
   * Call KOCHID API to authenticate user
   * @async
   * @param {*} formBodyDetails
   * @returns {Promise<object>} - A promise that resolves the token object.
   */
  async function getTokenFromAPI(formBodyDetails) {
    const baseURL = `${process.env.BASE_URL}/${SOCIAL_MEDIA_LOGIN}?code=${formBodyDetails?.code}&authGatewayType=${formBodyDetails?.authGatewayType}`;

    const request = {
      baseURL,
      method: "GET",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        "X-Application-Id": process.env.APP_ID,
      },
    };
    const data = await apiRequest(request);
    return data;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <p>redirecting...</p>
    </ErrorBoundary>
  );
};

export default AzureRedirectComponent;
