import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import logo from "../../../assets/images/sm/smart-apps-logo-light.png";
import logoDark from "../../../assets/images/sm/smart-apps-logo-dark.svg";
import { DEVICE_TYPES } from "../../../constants/eventDataTypes";
import { GlobalContext } from "smart-react";
import { ErrorFallback, Loader } from "smart-react";
import { Typography } from "@progress/kendo-react-common";
import LoginWithAzure from "../Components/Buttons/LoginWithAzure";
import LoginWithGoogle from "../Components/Buttons/LoginWithGoogle";
import LoginForm from "../Components/Forms/Login";
import { MessageAlert } from "smart-react";
import { Link } from "react-router-dom";
import "./Splash.scss";

/**
 * Main Login Screen
 * @returns {React.Element} returns main login screen
 */
const Splash = () => {
  const { dimensions, deviceInfo } = React.useContext(GlobalContext);
  const [isMobile, setIsMobile] = React.useState(null);
  const [isLoader, setIsLoader] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const loginGridClassName = `login-grid`;

  React.useEffect(() => {
    if (dimensions !== undefined) {
      if (
        deviceInfo?.type === DEVICE_TYPES.PHONE ||
        deviceInfo?.type === DEVICE_TYPES.TABLET
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [dimensions]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <>
        {isLoader && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
        {isMobile !== null && (
          <>
            {!isMobile ? (
              <div className={loginGridClassName}>
                <div className="login-rside">
                  <img
                    src={logo}
                    className="login-logo"
                    alt={`${process.env.APP_NAME} Logo`}
                  />
                </div>

                <div className="login-fside">
                  <div className="form-container">
                    <Typography.h6
                      className="k-font-bold"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Let's Sign You In.
                    </Typography.h6>

                    <Typography.p textAlign="center" className={"!k-mb-0"}>
                      To Continue, first Verify that it's You.
                    </Typography.p>
                    {errorMessage && (
                      <MessageAlert
                        type={"danger"}
                        message={errorMessage}
                        isIcon={true}
                        iconName={"error"}
                      />
                    )}
                    <LoginForm isLoader={isLoader} setIsLoader={setIsLoader} />
                    <div className="login-button-wrapper">
                      <p className="signup-link k-text-center">
                        {" "}
                        Not Signed up yet?{" "}
                        <Link to="/register">Sign Up Now</Link>
                      </p>
                      <hr />
                      <LoginWithAzure
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        text={"Sign in with Microsoft"}
                      />
                      <LoginWithGoogle
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        text={"Sign in with Google"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login-grid-mobile">
                <div className="login-mobile">
                  <img
                    src={logoDark}
                    className="login-logo-mobile"
                    alt={`${process.env.APP_NAME} Logo`}
                  />
                  <div className="form-container-mobile">
                    <Typography.h6
                      className="k-font-bold"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Let's Sign You In.
                    </Typography.h6>

                    <Typography.p textAlign="center">
                      To Continue, first Verify that it's You.
                    </Typography.p>
                    <LoginForm isLoader={isLoader} setIsLoader={setIsLoader} />
                    <div className="login-button-wrapper">
                      <p className="signup-link k-text-center">
                        {" "}
                        Not Signed up yet?{" "}
                        <Link to="/register">Sign Up Now</Link>
                      </p>
                      <hr />
                      <LoginWithAzure
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        text={"Microsoft"}
                      />
                      <LoginWithGoogle
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        text={"Google"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default Splash;
